import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import styles from "./Search.module.scss";

type Props = {
    searchInput: string;
    setSearchInput: React.Dispatch<React.SetStateAction<string>>;
};

export default function Search({ searchInput, setSearchInput }: Props) {
    return (
        <>
            <input
                type="text"
                placeholder="Zoeken"
                className={styles.input}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
            />
            <MagnifyingGlassIcon className={styles.icon} />
        </>
    );
}
