import { CogIcon, MapPinIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { IconData } from "typings/IconData";
import styles from "./Content.module.scss";

type ContentProps = {
    selectedIcon: IconData | null;
};

export default function Content({ selectedIcon }: ContentProps) {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleSlideChange = (swiper: any) => {
        setActiveIndex(swiper.activeIndex);
    };

    return (
        <div className={styles.container}>
            <div className={styles.section}>
                {selectedIcon?.function ? (
                    <div className={styles.subSection}>
                        <div className={styles.header}>
                            <CogIcon className={styles.icon} />
                            <h1 className={styles.title}>Wat doet het</h1>
                        </div>
                        <p className={styles.text}>{selectedIcon?.function}</p>
                    </div>
                ) : null}
                {selectedIcon?.location ? (
                    <div className={styles.subSection}>
                        <div className={styles.header}>
                            <MapPinIcon className={styles.icon} />
                            <h1 className={styles.title}>
                                Waar bevind zich dit
                            </h1>
                        </div>
                        <p className={styles.text}>{selectedIcon?.location}</p>
                    </div>
                ) : null}
            </div>
            <div>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    onSlideChange={handleSlideChange}
                    className={styles.swiper}
                >
                    {selectedIcon?.image_urls.map((image, index) => (
                        <SwiperSlide key={index}>
                            <img
                                src={image}
                                alt={selectedIcon?.name}
                                className={styles.image}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className={styles.pagination}>
                    {selectedIcon?.image_urls.map((_, index) => (
                        <div
                            key={index}
                            className={`${styles.dot} ${index === activeIndex ? styles.activeDot : styles.inactiveDot}`}
                        ></div>
                    ))}
                </div>
            </div>
        </div>
    );
}
