import Nav from "components/UI/Nav/Nav";
import styles from "./SymbolInfo.module.scss";
import Content from "components/Content/Content";
import { useEffect, useState } from "react";
import { IconData } from "typings/IconData";
import useGetIconData from "actions/usGetIconData";
import { useParams } from "@tanstack/react-router";
import Hero from "components/Hero/Hero";

export default function SymbolInfo() {
    const [selectedIcon, setSelectedIcon] = useState<IconData | null>(null);

    const { data: iconData } = useGetIconData();

    const id = useParams({
        from: "/$iconId",
        select: (params) => params.iconId,
    });

    useEffect(() => {
        if (iconData) {
            if (id) {
                const icon = iconData.find((icon) => icon.id.toString() === id);
                setSelectedIcon(icon || null);
            } else {
                setSelectedIcon(iconData[0]);
            }
        } else {
            setSelectedIcon(null);
        }
    }, [iconData, id]);

    return (
        <div className={styles.container}>
            <Nav />
            <Hero selectedIcon={selectedIcon} />
            <Content selectedIcon={selectedIcon} />
        </div>
    );
}
