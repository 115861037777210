import styles from "./Icon.module.scss";

type Props = {
    src: string;
    alt: string;
    onClick?: () => void;
};

export default function Icon({ src, alt, onClick }: Props) {
    return (
        <div onClick={onClick} className={styles.iconContainer}>
            <img src={src} alt={alt} className={styles.icon} />
        </div>
    );
}
