import useGetIconData from "actions/usGetIconData";
import Content from "components/Content/Content";
import Hero from "components/Hero/Hero";
import Nav from "components/UI/Nav/Nav";
import { useEffect, useState } from "react";
import { IconData } from "typings/IconData";
import styles from "./Home.module.scss";

export default function Home() {
    const [selectedIcon, setSelectedIcon] = useState<IconData | null>(null);

    const { data: iconData } = useGetIconData();

    useEffect(() => {
        if (iconData) {
            setSelectedIcon(iconData[0]);
        } else {
            setSelectedIcon(null);
        }
    }, [iconData]);

    return (
        <div className={styles.container}>
            <Nav />
            <Hero selectedIcon={selectedIcon} />
            <Content selectedIcon={selectedIcon} />
        </div>
    );
}
