import { IconData } from "typings/IconData";
import styles from "./Hero.module.scss";

type HeroProps = {
    selectedIcon: IconData | null;
};

export default function Hero({ selectedIcon }: HeroProps) {
    return (
        <div className={styles.heroContainer}>
            <div className={styles.heroContent}>
                <h1 className={styles.heroTitle}>{selectedIcon?.name}</h1>
                <img
                    src={selectedIcon?.symbol_url}
                    alt="aftappen"
                    className={styles.heroImage}
                />
            </div>
        </div>
    );
}
