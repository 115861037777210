import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
    createRootRoute,
    createRoute,
    createRouter,
    RouterProvider,
} from "@tanstack/react-router";
import { StrictMode } from "react";

import ReactDOM from "react-dom/client";
import Home from "routes/Home/Home";
import SymbolInfo from "routes/SymbolInfo/SymbolInfo";

import "styles/App.scss";

const rootRoute = createRootRoute();

const indexRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/",
    component: Home,
});

const dynamicRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/$iconId",
    component: SymbolInfo,
});

const routeTree = rootRoute.addChildren([indexRoute, dynamicRoute]);

const router = createRouter({ routeTree });

// Register the router instance for type safety
declare module "@tanstack/react-router" {
    interface Register {
        router: typeof router;
    }
}
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // Default behavior is to have a stale time of 0. This means that the query will be refetched on every mount.
            staleTime: 10 * 1000 * 60,
        },
    },
});

// Render the app
const rootElement = document.getElementById("app")!;
if (!rootElement.innerHTML) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
        <StrictMode>
            <QueryClientProvider client={queryClient}>
                <RouterProvider
                    router={router}
                    key={router.routesById.toString()}
                />
            </QueryClientProvider>
        </StrictMode>,
    );
}
