import { Squares2X2Icon } from "@heroicons/react/24/solid";

import styles from "./Top.module.scss";

type TopProps = {
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Top({ setIsOpen }: TopProps) {
    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <h1 className={styles.header}>ROC Ter Aa</h1>
            </div>
            <div
                onClick={() => setIsOpen((prev) => !prev)}
                className={styles.iconWrapper}
            >
                <Squares2X2Icon className={styles.icon} />
            </div>
        </div>
    );
}
