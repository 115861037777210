// Components==============
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
import { IconData } from "typings/IconData";
// =========================

export const getIconData = async () => {
    const { data } = await axios.get("api/symbols");
    return data as IconData[];
};

export default function useGetIconData(options?: UseQueryOptions<IconData[]>) {
    const query = useQuery<IconData[]>({
        queryKey: ["icons"],
        queryFn: getIconData,
        ...options,
    });

    return query;
}
