import { useNavigate } from "@tanstack/react-router";
import useGetIconData from "actions/usGetIconData";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import styles from "./Nav.module.scss";
import Icon from "./components/Icon.tsx/Icon";
import Search from "./components/Search/Search";
import Top from "./components/Top/Top";

export default function Nav() {
    const [isOpen, setIsOpen] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const navigate = useNavigate();

    const { data: iconData } = useGetIconData();

    console.log(iconData);

    const onIconClick = (id: string) => {
        navigate({
            to: `/${id}`,
            params: { iconId: id },
        });
        setIsOpen(false);
    };

    const filteredIconData = iconData?.filter((icon) => {
        return icon.name.toLowerCase().includes(searchInput.toLowerCase());
    });

    return (
        <div className={styles.container}>
            <Top setIsOpen={setIsOpen} />
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{
                            opacity: 0,
                            y: -5,
                            scaleY: 0,
                            transformOrigin: "top",
                        }}
                        animate={{ opacity: 1, y: 0, scaleY: 1 }}
                        exit={{ opacity: 0, y: -5, scaleY: 0 }}
                        transition={{ duration: 0.25, ease: "easeInOut" }}
                        className={styles.iconContainer}
                    >
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.3, delay: 0.1 }}
                            className={styles.searchContainer}
                        >
                            <Search
                                searchInput={searchInput}
                                setSearchInput={setSearchInput}
                            />
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.3, delay: 0.2 }}
                            className={styles.iconWrapper}
                        >
                            {filteredIconData?.map((pic) => (
                                <div key={pic.id} className={styles.iconItem}>
                                    <Icon
                                        src={pic.symbol_url}
                                        alt={pic.name}
                                        onClick={() =>
                                            onIconClick(pic.id.toString())
                                        }
                                    />
                                    <p className={styles.iconName}>
                                        {pic.name}
                                    </p>
                                </div>
                            ))}
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}
